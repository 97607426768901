import React from 'react'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'
import Helmet from 'react-helmet'
import Layout from '../templates/layout'
import Column2 from '../templates/column2'
import Header from '../organisms/header'
import Footer from '../organisms/footer'
import MainContent from '../organisms/main-content'
import Sidebar from '../organisms/sidebar'
import SinglePost from '../organisms/single-post'
import SEO from '../utils/seo'

export default ({ pageContext, data }) => {
  const { canonical } = pageContext
  const post = data.markdownRemark
  const title = post.frontmatter.title
  const tags = post.frontmatter.tags
  const datePublished = post.frontmatter.date
  const description = post.frontmatter.description || post.excerpt
  const thumbnail = post.frontmatter.thumbnail

  // Create breadcrumb list
  const breadcrumbList = [{ name: 'ikapro', item: 'https://ikapro.tech' }]
  if (tags && tags.length !== 0) {
    const tag = tags[0]
    breadcrumbList.push({
      name: tag.id,
      item: `https://ikapro.tech/${tag.slug}`,
    })
  }
  breadcrumbList.push({
    name: title,
    item: canonical,
  })

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        canonical={canonical}
        breadcrumbList={breadcrumbList}
        thumbnail={thumbnail}
      />
      <Helmet
        script={[
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              '@context': 'https://schema.org',
              '@graph': [
                {
                  '@type': 'BlogPosting',
                  headline: title,
                  author: 'gesomori',
                  datePublished: dayjs(datePublished).toISOString(),
                },
                {
                  '@type': 'BreadcrumbList',
                  itemListElement: breadcrumbList.map(({ item, name }, i) => ({
                    '@type': 'ListItem',
                    position: i + 1,
                    name: name,
                    item: item,
                  })),
                },
              ],
            }),
          },
        ]}
      />
      <Header />
      <Column2>
        <MainContent>
          <SinglePost post={post} />
        </MainContent>
        <Sidebar />
      </Column2>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(format: PLAIN, truncate: true)
      frontmatter {
        title
        date
        description
        thumbnail
        tags {
          id
          slug
        }
      }
      fields {
        slug
      }
    }
  }
`

import React from "react"

export default () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="200"
    height="200"
    viewBox="0 0 200 200"
    style={{
      display: "inline-block",
      fontSize: "inherit",
      height: "1em",
      verticalAlign: "-.125em",
      width: "0.875em",
      overflow: "visible",
    }}
  >
    <path
      fill="#00a4de"
      d="M170.583 0c16.25 0 29.417 13.167 29.417 29.417v141.167c0 16.25-13.167 29.417-29.417 29.417h-141.167c-16.25 0-29.417-13.167-29.417-29.417v-141.167c0-16.25 13.167-29.417 29.417-29.417h141.167zM139.708 120.583c-6.5 0-11.75 5.25-11.75 11.75s5.25 11.783 11.75 11.783 11.75-5.375 11.75-11.875-5.25-11.75-11.75-11.75zM71.75 143.725c10 0 17.133-0.35 21.5-1 4.383-0.7 8.133-1.85 11-3.433 3.75-1.933 6.5-4.7 8.5-8.25s3-7.625 3-12.333c0-6.5-1.75-11.692-5.25-15.583-3.5-4-8.25-6.117-14.5-6.617 5.5-1.5 9.633-3.75 12.133-6.75 2.625-2.867 3.875-6.867 3.875-11.867 0-4-0.858-7.375-2.5-10.5-1.75-3-4.108-5.375-7.358-7.25-2.875-1.625-6.125-2.625-10.125-3.375-3.867-0.617-10.75-1-20.617-1h-24.292v87.383h24.633zM77.883 108.85c5.875 0 9.875 0.733 12 2.183 2.25 1.5 3.25 4.125 3.25 7.75 0 3.375-1.125 5.75-3.5 7.125-2.25 1.5-6.375 2.117-12 2.117h-8.383v-19.142h8.75zM150.017 114.733v-58.833h-20.5v58.833h20.483zM74.375 75.667c5.917 0 9.875 0.667 11.933 2 2.042 1.333 3.058 3.625 3.058 6.917 0 3.167-1.083 5.383-3.25 6.7-2.208 1.283-6.225 1.933-12.1 1.933h-4.75v-17.55h5.125z"
    ></path>
  </svg>
)

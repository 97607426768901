import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TwitterShareButton, FacebookShareButton } from 'react-share'
import { css } from '@emotion/core'
import Card from '../molecules/card'
import TagList from '../molecules/tag-list'
import Text from '../molecules/text'
import Date from '../atoms/date'
import HatenabookmarkIcon from '../atoms/hatenabookmark-icon'

const dateStyle = css`
  margin-bottom: 12px;
`

const titleStyle = css`
  margin-bottom: 32px;
  line-height: 1.4;

  @media (max-width: 560px) {
    font-size: 28px;
  }
`

const tagListStyle = css`
  margin-bottom: 40px;
`

const shareButtonsStyle = css`
  display: flex;
  font-size: 24px;
  justify-content: flex-end;

  a,
  div {
    cursor: pointer;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.6;
    }
  }
`

const textStyle = css`
  margin-bottom: 32px;
`

export default ({ post }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
          siteUrl
          twitterUsername
        }
      }
    }
  `)

  const { siteUrl, siteName, twitterUsername } = site.siteMetadata
  const postTitle = post.frontmatter.title
  const postTags = post.frontmatter.tags
  const postHtml = post.html
  const shareURL = encodeURI(`${siteUrl}${post.fields.slug}`)

  return (
    <Card>
      <Date css={dateStyle} date={post.frontmatter.date} />
      <h1 css={titleStyle}>{postTitle}</h1>
      <TagList tags={postTags} css={tagListStyle} />
      <Text css={textStyle} html={postHtml} />
      <div css={shareButtonsStyle}>
        <TwitterShareButton
          title={`${postTitle} - ${siteName}`}
          via={twitterUsername}
          url={shareURL}
        >
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </TwitterShareButton>
        <a
          href={`https://b.hatena.ne.jp/entry/s/ikapro.tech${post.fields.slug}`}
          title="このエントリーをはてなブックマークに追加"
          target="_blank"
          rel="noreferrer noopener"
        >
          <HatenabookmarkIcon />
        </a>
        <FacebookShareButton url={shareURL}>
          <FontAwesomeIcon icon={['fab', 'facebook-square']} />
        </FacebookShareButton>
      </div>
    </Card>
  )
}
